import React from 'react';
import cx from 'classnames';
import styles from './ContentWrapper.module.scss';

const ContentWrapper = ({
  children, className, isMarkdown, style,
}) => (
  <div
    className={cx({ 'markdown-body': isMarkdown }, styles.contentWrapper, { [className]: className }, 'mt4')}
    style={style}
  >
    {children}
  </div>
);

export default ContentWrapper;
