import React from 'react';
import { graphql } from 'gatsby';
import { IntlProvider } from 'react-intl';
import BlogLayout from '../components/blogLayout';

import SEO from '../components/SEO';
import ContentWrapper from '../components/ContentWrapper';
import Footer from '../components/Footer';
import Header from '../components/Header';
import BlogList from '../components/BlogList';
import getMessages from '../locales/getMessages';

const messages = getMessages();

const BlogRoute = ({ data }) => {
  const blogData = data.allMarkdownRemark.edges;
  const { url } = data.site.siteMetadata;
  const locale = 'en';
  return (
    <BlogLayout>
      <SEO
        title="Blog - EverTool"
        subtitle="EverTool official blog. Discuss the methods to enhance Evernote productivity"
        url={url}
        defer={false}
        locale="en"
      />
      <IntlProvider locale={locale} messages={messages[locale]} textComponent={React.Fragment}>
        <div>
          <Header />
          <ContentWrapper isMarkdown>
            <h1 className="pt5">Blog</h1>
            <div className="blog-post">
              {blogData.map((d) => {
                const { id, frontmatter } = d.node;
                const {
                  title, path, date, tags,
                } = frontmatter;
                return (
                  <BlogList key={id} path={path} title={title} date={date} tags={tags} />
                );
              })}
            </div>
          </ContentWrapper>
        </div>
        <Footer locale={locale} />
      </IntlProvider>
    </BlogLayout>
  );
};

export default BlogRoute;

export const pageQuery = graphql`
  query BlogQuery {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "blog" }, locale: {eq: "en"} } },
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            tags
            path
            title
            date(formatString: "DD MMMM, YYYY")
            type
          }
        }
      }
    }
    site {
      siteMetadata {
        url
      }
    }
  }
`;
