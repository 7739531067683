import React from 'react';
import Link from '../Link';
import styles from './style.module.scss';

const BlogList = ({ path, title, date }) => (
  <div>
    <h3 className={styles.blogListTitle}>
      <Link to={path}>{title}</Link>
    </h3>
    <p><span className={styles.blogListDate}>{date}</span></p>
  </div>
);

export default BlogList;
