import React from 'react';
import Helmet from 'react-helmet';

import styles from './blogLayout.module.scss';

const BlogLayout = ({ children }) => (
  <div className={styles.wrapper}>
    <Helmet defaultTitle="EverTool" defer={false} />
    <div>
      {children}
    </div>
  </div>
);

export default BlogLayout;
